import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LottieLoaderComponent } from '../shared/components/lottie-loader/lottie-loader.component';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { NgxSpinnerModule } from 'ngx-spinner';


export function playerFactory() {
    return player;
}

@NgModule({
    imports: [
        CommonModule,
        NgxSpinnerModule.forRoot({
            type: 'line-scale-party'
        }),
        LottieModule.forRoot({
            player: playerFactory
        }),
        LottieLoaderComponent,
    ],
    exports: [
        LottieLoaderComponent,
        NgxSpinnerModule,
    ],
})
export class AnimationsModule { }
